// components/CheatSheet.js
import React from 'react';
import CollapsibleTree from './CollapsibleTree';

function CheatSheet() {
  return (
    <div>
      <CollapsibleTree />
    </div>
  );
}

export default CheatSheet;
